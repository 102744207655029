import { ErrorBoundary, useBreakpoint } from '@buzzfeed/react-components';
import SkipToContent from '@buzzfeed/react-components/lib/components/SkipToContent';
import { captureException } from '@sentry/nextjs';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef, useState, useEffect } from 'react';
import DestinationContext from '../../../contexts/destination';
import { useTranslation } from '../../../i18n';
import dynamicImport from '../../../utils/dynamic-import';
import { isNews } from '../../../utils/isNews';
import { isSponsored } from '../../../utils/isSponsored';
import { ActionBar } from '../../ActionBar';
import CommentsContext from '../../../contexts/comments';
import CommentsWrapper from '../../CommentsWrapper';
import AdUnit from '../../Ads/AdUnit';
import AdInlineTrending from '../../Ads/components/AdInlineTrending';
import AmazonOneLink from '../../AmazonOneLink';
import CommerceSEOLinks from '../../CommerceSEOLinks';
import ContentBottom from '../../ContentBottom';
import FooterCommunity from '../../FooterCommunity';
import Headline from '../../Headline';
import BuzzNewsFooter from '../../NewsFooter';
import NewsletterSignup from '../../NewsletterSignup/lazy';
import Package from '../../Package/lazy';
import { PageOverlay } from '../../PageOverlay';
import PubFeed from '../../PubFeed';
import AdmiralScript from '../../AdmiralScript';
import QuizPartyPromo from '../../QuizPartyPromo';
import MobileAppPromo from '../../MobileAppPromo';
import Outbrain from '../../Outbrain';
import Reactions from '../../Reactions/lazy';
import ReactionsAnimated from '../../ReactionsAnimated/lazy';
import RetailMediaNetwork from '../../RetailMediaNetwork';
import { BottomRecircList, RelatedRecircList } from '../../RecircList/variants';
import ShareBar from '../../ShareBar';
import Sidebar from '../../Sidebar';
import StudiosPromoUnit from '../../StudiosPromoUnit';
import Subbuzzes from '../../Subbuzzes';
import TakeQuizYourself from '../../TakeQuizYourself';
import TaboolaJP from '../../Taboola/JP';
import TopicPromo from '../../TopicPromos';
import TopicTags from '../../TopicTags';
import TrendingProducts from '../../TrendingProducts';
import TrendingShoppingCarousel from '../../TrendingShoppingCarousel/lazy';
import layoutStyles from '../post.module.scss';
import ABeagleContext from "../../../contexts/abeagle";
import EmojiReactions from '../../EmojiReactions';
import { ElectionCTA } from '../../ElectionCTA';
// import ShoppyBotPromo from '../../ShoppyBotPromo';

const WalmartBundle = dynamicImport(
  () => import(/* webpackChunkName: "walmart-promo" */ '../../WalmartBundle'),
  { ssr: false }
);

const CommerceUserReviews = dynamicImport(
  () => import(/* webpackChunkName: "commerce-user-reviews" */ '../../CommerceUserReviews'),
  { ssr: true }
);

const QuizResultConfetti = dynamicImport(
  () => import(/* webpackChunkName: "quiz-result-confetti" */ '../../QuizResultConfetti'),
  { ssr: false }
);

const GiftguideCategoryBanner = dynamicImport(
  () => import(/* webpackChunkName: "gg-category-banner" */ '../../GiftguideCategoryBanner'),
  { ssr: false }
);

function DefaultPost({
  buzz,
  subbuzzData,
  badges,
  showOverlay,
  setShowOverlay,
  giftguideHeader
}) {
  const { t } = useTranslation('common');
  const ref = useRef(null);
  const { destination } = useContext(DestinationContext);
  const { commentsEnabled } = useContext(CommentsContext);
  const isMobile = useBreakpoint('(max-width:500px)'); // page level break is based on bf_header_ui break
  const isDesktop = useBreakpoint('(min-width: 64rem)');
  const showConfettiWithReactions = buzz.isTrivia || buzz.isPersonality;
  const showTopShareBar =
    (!(buzz.shouldHideBuzzSharing || buzz.shouldShowPostContentOnly) &&
      !isMobile) ||
    isNews(buzz);
  const showBottomShareBar =
    !buzz.shouldHideBuzzSharing &&
    !buzz.shouldShowPostContentOnly &&
    destination === 'buzzfeed';
  const [commentCount, setCommentCount] = useState(null);
  const { getFeatureFlagValue, experiments, getExperimentValue } = useContext(ABeagleContext);
  const [showShoppyBot, setShowShoppyBot] = useState(false);
  const [isShowShoppingBFN, setIsShowShoppingBFN] = useState(false);
  const [AdsRetailMediaNetwork, setAdsRetailMediaNetwork] = useState('control');
  // 1710959400 - Date and time (GMT): Wednesday, March 20, 2024 7:30:00 PM GMT+01:00
  const showEmojiReactions = !!(buzz.published && parseInt(buzz.published, 10) > 1710959400);

  useEffect(() => {
    const shoppyFlagValue = getFeatureFlagValue('shoppybot_bpage_promo');
    const shoppyExperimentValue = getExperimentValue('shoppy-bpage-test', {
    rejectErrors: false,
    });

    if (shoppyExperimentValue === 'chat' || shoppyFlagValue ) {
      setShowShoppyBot(true);
    } else setShowShoppyBot(false);
  }, [getFeatureFlagValue, getExperimentValue]);

  // All shopping recirc tests go here
  useEffect(() => {
    const shoppingModuleBFN = getExperimentValue('RT-946-shopping-recirc-bfn', {
      rejectErrors: false,
    });

    if (isNews(buzz) && shoppingModuleBFN === 'var1') {
      setIsShowShoppingBFN(true);
    }
  }, [getExperimentValue]);

  // Retail Media Network AB Test
  useEffect(() => {
    if (experiments && experiments.loaded) {
      setAdsRetailMediaNetwork(
        getExperimentValue('ads_retail_media_network', {
          rejectErrors: false,
        })
      );
    }
  }, [experiments, getExperimentValue]);


  const onOverlayClick = useCallback(() => {
    setShowOverlay(false);
  }, [setShowOverlay]);

  return (
    <main
      id="buzz-content"
      className={`${layoutStyles.main} ${layoutStyles[destination]} embed-content`}
      ref={ref}
    >
      <ErrorBoundary onError={captureException}>
        <AdmiralScript />
        <AmazonOneLink buzz={buzz} />
      </ErrorBoundary>
      <div
        className={`${layoutStyles.article} ${layoutStyles.default} ${layoutStyles[destination]} embed-post`}
      >
        <div className={layoutStyles.content}>
          <article>
            <Headline buzz={buzz} badges={badges} />
            <ActionBar
              buzz={buzz}
              commentCount={commentCount}
              showShareBar={showTopShareBar}
              destination={destination}
            />
            <TrendingProducts buzz={buzz} postRef={ref} />
            {buzz.tags.some((tag) =>
              ['etsy-strategy', 'etsy_strategy', 'etsy strategy'].includes(tag)
            ) && (
              <AdInlineTrending
                pixiedust={{
                  unit_type: 'buzz_body',
                  unit_name: buzz.id,
                  position_in_unit: 0,
                }}
              />
            )}
            <MobileAppPromo />
            <QuizPartyPromo />
            <Subbuzzes
              buzz={buzz}
              key={buzz.id}
              subbuzzData={subbuzzData}
              pixiedust={{
                unit_type: 'buzz_body',
                unit_name: buzz.id,
                position_in_unit: 0,
              }}
            />
            {buzz.hasStudiosPromoUnit && <StudiosPromoUnit buzz={buzz} />}
            {isNews(buzz) && <TopicTags buzz={buzz} />}
            <CommerceSEOLinks buzz={buzz} />
            <BuzzNewsFooter buzz={buzz} />
            <ContentBottom buzz={buzz} />
            {!isDesktop && buzz.walmartBundle && <WalmartBundle buzz={buzz} />}
          </article>

          <ErrorBoundary onError={captureException}>
            <TakeQuizYourself buzz={buzz} />
            <AdUnit
              type="subbuzz"
              pixiedust={{
                unit_type: 'buzz_bottom',
                unit_name: buzz.id,
                position_in_unit: 0,
              }}
            />
            {buzz.classification.edition === 'Japan' && (
              <TaboolaJP isMobile={isMobile}/>
            )}
            {buzz?.classification?.section === 'Shopping' && (
              <GiftguideCategoryBanner
                buzz={buzz}
                giftguideHeader={giftguideHeader}
              />
            )}
            {!showEmojiReactions && (showConfettiWithReactions ? <ReactionsAnimated /> : <Reactions />)}
            {showEmojiReactions && <EmojiReactions contentId={buzz.id} isSponsored={isSponsored(buzz)}/>}
            {commentsEnabled && <CommentsWrapper
              buzz={buzz}
              commentCount={commentCount}
              onCommentsLoaded={setCommentCount}
              firstPage={100}
              repliesFirstPage={2}
              perPage={100}
            />}
            {commentsEnabled && <TopicPromo buzz={buzz} subunitName="comments" subunitType="component" unitType="buzz_bottom" />}
            {showBottomShareBar && (
              <ShareBar
                buzz={buzz}
                heading={t('share_article')}
                position="bottom_share_bar"
                type="pagelevel"
                className={`${layoutStyles.shareBottom} ${
                  buzz.is_quiz ? layoutStyles.quiz : ''
                }`}
              />
            )}
            {!buzz.isAd && buzz.laser_tags?.bf_content_description?.topic?.some(
              (tag) => tag.tag_name === '2024_elections') && <ElectionCTA />}
            {AdsRetailMediaNetwork === 'var1' && (
              <RetailMediaNetwork version={AdsRetailMediaNetwork} buzz={buzz}/>
            )}
            <PubFeed />
            {
              buzz.tags.some(tag => tag === '--commerce-user-reviews') && <CommerceUserReviews buzz={buzz} />
            }
            {
              (
                destination === 'buzzfeed' &&
                !buzz.isShopping &&
                !isNews(buzz) &&
                <>
                  <Package
                    style={{ marginBottom: '3rem' }}
                    isNewDesign={true}
                  />
                  {AdsRetailMediaNetwork === 'var2' && (
                    <RetailMediaNetwork version={AdsRetailMediaNetwork} buzz={buzz}/>
                  )}
                  <AdUnit
                    type="story-bpage-desktop"
                    pixiedust={{
                      unit_type: 'buzz_bottom',
                      unit_name: buzz.id,
                      position_in_unit: 1,
                    }}
                    style={{ marginTop: '-2rem' }}
                  />
                </>
              ) || (
                AdsRetailMediaNetwork === 'var2' && (
                  <RetailMediaNetwork version={AdsRetailMediaNetwork} buzz={buzz}/>
                )
              )
            }
            <RelatedRecircList />
            {
              destination === 'buzzfeed' &&
              buzz.isShopping &&
              !isNews(buzz) &&
              <>
                <AdUnit
                  type="story-bpage-desktop"
                  pixiedust={{
                    unit_type: 'buzz_bottom',
                    unit_name: buzz.id,
                    position_in_unit: 1,
                  }}
                />
                <Package
                  style={{ marginTop: isMobile ? '1rem' : '-2rem' }}
                  isNewDesign={true}
                />
              </>
            }
            {showShoppyBot &&
              <>
                <div style={{ height: isMobile ? '575px' : '700px', margin: '40px 0'}}>
                  <iframe
                    border="0"
                    height="100%"
                    id="chatframe"
                    scrolling="no"
                    src={`/ai-ui/games/shoppy-chat?page=${buzz.id}`}
                    width="100%"
                  >
                  </iframe>
                </div>
               {/* <ShoppyBotPromo buzzId={buzz.id} /> */}
              </>
            }
            <NewsletterSignup />
            <FooterCommunity />
            {isMobile && (
              <AdUnit
                type="bigstory"
                pixiedust={{
                  unit_type: 'buzz_bottom',
                  unit_name: buzz.id,
                  position_in_unit: 1,
                }}
              />
            )}
            {showConfettiWithReactions && <QuizResultConfetti />}
          </ErrorBoundary>
        </div>
        <Sidebar
          pixiedust={{
            unit_type: 'sidebar',
            unit_name: 'right',
            position_in_unit: 0,
          }}
        />
      </div>
      <ErrorBoundary onError={captureException}>
        <TrendingShoppingCarousel />
        {buzz.classification.edition === 'Japan' && (
          <TaboolaJP placement={2} isMobile={isMobile}/>
        )}
        {isNews(buzz) && <SkipToContent jumpTo="news-footer" className="skipToFooter" label="Skip to footer" />}
        {isNews(buzz) &&
          (
            isShowShoppingBFN ?
            <Package
              style={{
                marginBottom: '3rem',
                marginTop: '-2rem',
              }}
              isNewDesign={true}
            />
            :
            <Outbrain />
          )
        }
        <BottomRecircList />
      </ErrorBoundary>
      {showOverlay && <PageOverlay onOverlayClick={onOverlayClick} />}
    </main>
  );
}

DefaultPost.propTypes = {
  buzz: PropTypes.object.isRequired,
  badges: PropTypes.array,
  subbuzzData: PropTypes.shape({
    assets: PropTypes.object,
    subbuzzes: PropTypes.array,
  }),
};

export default DefaultPost;
